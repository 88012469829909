// Please keep keys lowercase!
exports.languageTexts = {
  tr: {
    meta_description:
      'Bilgisayar Bilimleri, Yazılım Mühendisliği, Kültür Sanat ve hayatla ilgili genel konularda blog yazıları.',
    meta_tags:
      'hüseyin küçük, hüseyin, küçük, ege üniversitesi, bilgisayar bilimleri, yazılım, php, golang, javascript, benzinlitre, kafatopu, faladdin, masomo',
    translated_by: 'Yazar tarafından şu dillere çevrildi:',
    all_translated: '',
    read_original: 'Orjinali oku',
    read_time: 'dk okuma',
    translated: '',
    personal_blog: ' kişisel blog.',
    software_engineer: 'Yazılım Mühendisi.',
    url_category: 'kategori',
  },
  en: {
    meta_description:
      'Blog posts on Computer Science, Software Engineering, Culture, Arts and general life-related topics.',
    meta_tags:
      'hüseyin küçük, hüseyin, küçük, ege university, computer science, software, php, golang, javascript, benzinlitre, headball, faladdin, masomo',
    translated_by: 'Translated by author into:',
    all_translated: 'View all translated posts',
    read_original: '',
    read_time: 'min read',
    translated: 'These articles have been translated.',
    personal_blog: "'s personal blog.",
    software_engineer: 'Software Engineer.',
    url_category: 'category',
  },
  de: {
    meta_description:
      'Blogbeiträge zu Informatik, Softwaretechnik, Kultur, Kunst und allgemeinen lebensbezogenen Themen.',
    meta_tags:
      'hüseyin küçük, hüseyin, küçük, ege-universität, informatik, software, php, golang, javascript, benzinlitre, headball, faladdin, masomo',
    translated_by: 'Übersetzt vom autor in:',
    all_translated: 'Alle übersetzten beiträge anzeigen',
    read_original: '',
    read_time: 'min lesen',
    translated: 'Diese artikel wurden übersetzt.',
    personal_blog: 's persönlicher blog.',
    software_engineer: 'Softwareentwickler.',
    url_category: 'categorie',
  },
  it: {
    meta_description:
      'Post di blog su informatica, ingegneria del software, cultura, arte e argomenti relativi alla vita in generale.',
    meta_tags:
      'hüseyin küçük, hüseyin, küçük, ege university, informatica, software, php, golang, javascript, benzinlitre, headball, faladdin, masomo',
    translated_by: "Tradotto dall'autore in:",
    all_translated: 'Visualizza tutti i post tradotti',
    read_original: '',
    read_time: 'min leggi',
    translated: 'Questi articoli sono stati tradotti.',
    personal_blog: ' blog personale di.',
    software_engineer: 'Ingegnere del Software.',
    url_category: 'categoria',
  },
  fr: {
    meta_description:
      "Articles de blog sur l'informatique, l'ingénierie logicielle, la culture et les arts et des sujets de la vie en général.",
    meta_tags:
      'huseyin kucuk, huseyin, kucuk, université ege, informatique, logiciel, php, golang, javascript, benzelitre, kafatopu, faladdin, masomo',
    translated_by: "Traduit par l'auteur en :",
    all_translated: '',
    read_original: "Lire l'original",
    read_time: 'lecture min',
    translated: '',
    personal_blog: ' blog personnel.',
    software_engineer: 'Ingénieur Logiciel.',
    url_category: 'catégorie',
  },
  ru: {
    meta_description:
      'Сообщения в блогах по компьютерным наукам, разработке программного обеспечения, культуре, искусству и общим темам, связанным с жизнью.',
    meta_tags:
      'hüseyin küçük, hüseyin, küçük, Эгейский университет, информатика, программное обеспечение, php, golang, javascript, benzinlitre, головной убор, Фаладдин, mасомо',
    translated_by: 'Переведено автором:',
    all_translated: 'Посмотреть все переведенные сообщения',
    read_original: '',
    read_time: 'мин чтение',
    translated: 'Эти статьи были переведены.',
    personal_blog: ' личный блог.',
    software_engineer: 'Инженер-программист.',
    url_category: 'категория',
  },
};
